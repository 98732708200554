// src/context/CategoryProvider.tsx

import React, { createContext, useState, useEffect, PropsWithChildren } from "react";
import axios from "axios";
import ContentCategory from "src/global__types/ContentCategory";

interface CategoryContextType {
	categories: ContentCategory[];
	category: ContentCategory | null;
	fetchCategories: () => void;
	fetchCategoryById: (id: number) => void;
}

export const ContentContext = createContext<CategoryContextType>({} as CategoryContextType);

export const ContentProvider: React.FC<PropsWithChildren<{}>> = ({ children }) => {
	const [categories, setCategories] = useState<ContentCategory[]>([]);
	const [category, setCategory] = useState<ContentCategory | null>(null);

	async function fetchCategories() {
		try {
			const response = await axios.get("/api/content/categories"); // Der Pfad beginnt nun mit einem Schrägstrich

			if (response.status === 200) {
				setCategories(response.data);
			} else {
				console.error("Fehler beim Abrufen der Kategorien:", response.status);
			}
		} catch (error) {
			console.error("Fehler beim Abrufen der Kategorien:", error);
		}
	}

	async function fetchCategoryById(id: number) {
		try {
			const response = await axios.get(`/api/content/categories/${id}`); // Auch hier sicherstellen, dass der Pfad absolut ist

			if (response.status === 200) {
				setCategory(response.data);
			} else if (response.status === 404) {
				console.error("Kategorie nicht gefunden.");
			} else {
				console.error("Fehler beim Abrufen der Kategorie:", response.status);
			}
		} catch (error) {
			console.error("Fehler beim Abrufen der Kategorie:", error);
		}
	}

	useEffect(() => {
		fetchCategories();
	}, []);

	return (
		<ContentContext.Provider
			value={{
				categories,
				category,
				fetchCategories,
				fetchCategoryById,
			}}
		>
			{children}
		</ContentContext.Provider>
	);
};
