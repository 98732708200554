//import { ReactNode, useEffect } from "react";
import { Route, BrowserRouter as Router, Routes } from "react-router-dom";
import "./App.css";
import ScrollToTop from "./ScrollToTop";
import Footer from "./components/ui__integration/navigation/Footer";
import Navbar from "./components/ui__integration/navigation/Navbar";
import Datenschutz from "./pages/comInfo/Datenschutz";
import Impressum from "./pages/comInfo/Impressum";
import NotFoundPage from "./pages/NotFound/NotFoundPage";
import Shop from "./pages/Shop/Shop";
import { CustomProductProvider } from "./context/ProductProvider";
import Landingpage from "./pages/Landingpage/Landingpage";
import ProductDetail from "./pages/Shop/ProductDetail";
import { ContentProvider } from "./context/ContentProvider";
import CategoryPage from "./components/ui__integration/landingCategoryGallery/LandingCategoryWhole";
import ImagePage from "./components/ui__integration/landingCategoryGallery/LandingCategoryCertain";

function App() {
	return (
		<Router>
			<CustomProductProvider>
				<ContentProvider>
					<div className="App">
						<ScrollToTop />
						<Navbar />
						<Routes>
							<Route path="/" element={<Landingpage />} />
							<Route path="/category/:id" element={<CategoryPage />} />
							<Route path="/image/:id" element={<ImagePage />} />
							<Route path="/impressum" element={<Impressum />} />
							<Route path="/datenschutz" element={<Datenschutz />} />
							<Route path="/*" element={<NotFoundPage />} />
							<Route path="/shop" element={<Shop />} />
							<Route path="/shop/:articleId" element={<ProductDetail />} />
						</Routes>
						<Footer />
					</div>
				</ContentProvider>
			</CustomProductProvider>
		</Router>
	);
}

export default App;
