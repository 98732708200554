import { useEffect } from "react";
import "./Datenschutz.css";

function Datenschutz() {
	useEffect(() => {
		document.title = "Metallbau-Fleischmann | Datenschutz";
	}, []);
	return (
		<div className="ds-container pt-32 pb-32">
			<div className="relative isolate pt-2 max-w-[1280px] mx-[48px] text-left">
				<h2 className="mb-2 text-3xl font-semibold text-gray-900 text-left pb-12">Datenschutz</h2>
				<div className="datasec-info">
					<h3>Allgemeine Hinweise</h3>
					<br />
					<p>
						Die folgenden Hinweise geben einen einfachen Überblick darüber, was mit Ihren personenbezogenen
						Daten passiert, wenn Sie unsere Website besuchen. <br />
						Personenbezogene Daten sind alle Daten, mit denen Sie persönlich identifiziert werden können.
						Ausführliche Informationen zum Thema Datenschutz entnehmen Sie der unter diesem Text
						aufgeführten Datenschutzerklärung.{" "}
					</p>
					<br />
					<br />
					<div className="horiz-divider"></div>
					<h3>Datenerfassung auf unserer Webseite</h3>
					<br />
					<h4 className="datasec-q">Wer ist verantwortlich für die Datenerfassung auf dieser Webseite?</h4>
					<p>
						Die Datenverarbeitung auf dieser Webseite erfolgt durch den Webseitenbetreiber. Dessen
						Kontaktdaten können Sie dem Impressum dieser Webseite entnehmen.
					</p>
					<br />

					<h4 className="datasec-q">Wie erfassen wir Ihre Daten?</h4>
					<p>
						Ihre Daten werden zum einen dadurch erhoben, dass Sie uns diese mitteilen. Hierbei kann es sich
						z.B. um Daten handeln, die Sie in ein Kontaktformular eingeben.
					</p>
					<br />
					<p>
						Andere Daten werden automatisch beim Besuch der Webseite durch unsere IT-Systeme erfasst. Das
						sind vor allem technische Daten (z.B. Internetbrowser, Betriebssystem oder Uhrzeit des
						Seitenaufrufs). Die Erfassung dieser Daten erfolgt automatisch, sobald Sie unsere Website
						betreten.
					</p>
					<br />
					<h4 className="datasec-q">Wofür nutzen wir Ihre Daten?</h4>
					<p>
						Ein Teil der Daten wird erhoben, um eine fehlerfreie Bereitstellung der Webseite zu
						gewährleisten. Andere Daten können zur Analyse Ihres Nutzerverhaltens verwendet werden.
					</p>
					<br />
					<h4 className="datasec-q">Welche Rechte haben Sie bezüglich Ihrer Daten?</h4>
					<p>
						Sie haben jederzeit das Recht, unentgeltlich Auskunft über Herkunft, Empfänger und Zweck Ihrer
						gespeicherten personenbezogenen Daten zu erhalten. Sie haben außerdem das Recht, die
						Berichtigung, Sperrung oder Löschung dieser Daten zu verlangen. Hierzu sowie zu weiteren Fragen
						zum Thema Datenschutz können Sie sich jederzeit unter der im Impressum angegebenen Adresse an
						uns wenden. Des Weiteren steht Ihnen ein Beschwerderecht bei der zuständigen Aufsichtsbehörde
						zu.
					</p>
					<br />
					<br />
					<div className="horiz-divider"></div>
					<h3>Datenschutz</h3>
					<br />
					<p>
						Die Betreiber dieser Seiten nehmen den Schutz Ihrer persönlichen Daten sehr ernst. Wir behandeln
						Ihre personenbezogenen Daten vertraulich und entsprechend den gesetzlichen
						Datenschutzvorschriften sowie dieser Datenschutzerklärung.
						<br />
						<br />
						Wenn Sie diese Webseite benutzen, werden verschiedene personenbezogene Daten erhoben.
						Personenbezogene Daten sind Daten, mit denen Sie persönlich identifiziert werden können. Die
						vorliegende Datenschutzerklärung erläutert, welche Daten wir erheben und wofür wir sie nutzen.
						Sie erläutert auch, wie und zu welchem Zweck das geschieht.
						<br />
						<br />
						Wir weisen darauf hin, dass die Datenübertragung im Internet (z. B. bei der Kommunikation per
						E-Mail) Sicherheitslücken aufweisen kann. Ein lückenloser Schutz der Daten vor dem Zugriff durch
						Dritte ist nicht möglich.
					</p>
					<br />
					<br />
					<div className="horiz-divider"></div>
					<h3>Hinweis zur verantwortlichen Stelle</h3>
					<br />
					<p>
						Die verantwortliche Stelle für die Datenverarbeitung auf dieser Webseite ist:
						<br />
						<br />
						Günter Fleischmann GmbH
						<br />
						Netzschkauer Str. 28b
						<br />
						Deutschland, 08499 Reichenbach OT Mylau
						<br />
						<br />
						<br />
						Telefon: 03765 / 31118
						<br />
						E-Mail: info@metallbau-fleischmann.de
						<br />
						<br />
						<br />
						Verantwortliche Stelle ist die natürliche oder juristische Person, die allein oder gemeinsam mit
						anderen über die Zwecke und Mittel der Verarbeitung von personenbezogenen Daten (z.B. Namen,
						E-Mail-Adressen o. Ä.) entscheidet.
					</p>
					<br />
					<br />
					<div className="horiz-divider"></div>
					<h3>Widerruf Ihrer Einwilligung zur Datenverarbeitung</h3>
					<br />
					<p>
						Sie haben das Recht, Daten, die wir auf Grundlage Ihrer Einwilligung oder in Erfüllung eines
						Vertrags automatisiert verarbeiten, an sich oder an einen Dritten in einem gängigen,
						maschinenlesbaren Format aushändigen zu lassen. Sofern Sie die direkte Übertragung der Daten an
						einen anderen Verantwortlichen verlangen, erfolgt dies nur, soweit es technisch machbar ist.{" "}
					</p>
					<br />
					<br />
					<div className="horiz-divider"></div>
					<h3>SSL- bzw. TLS-Verschlüsselung</h3>
					<p>
						<br />
						Diese Seite nutzt aus Sicherheitsgründen und zum Schutz der Übertragung vertraulicher Inhalte,
						wie zum Beispiel Anfragen, die Sie an uns als Seitenbetreiber senden, eine SSL-bzw.
						TLS-Verschlüsselung. Eine verschlüsselte Verbindung erkennen Sie daran, dass die Adresszeile des
						Browsers von “http://” auf “https://” wechselt und an dem Schloss-Symbol in Ihrer Browserzeile.
						<br />
						Wenn die SSL- bzw. TLS-Verschlüsselung aktiviert ist, können die Daten, die Sie an uns
						übermitteln, nicht von Dritten mitgelesen werden.
					</p>
					<br />
					<br />
					<div className="horiz-divider"></div>
					<h3>Datenerfassung auf unserer Webseite - Kontaktformular</h3>
					<p>
						<br />
						Wenn Sie uns per Kontaktformular Anfragen zukommen lassen, werden Ihre Angaben aus dem
						Anfrageformular inklusive den von Ihnen dort angegebenen Kontaktdaten zwecks Bearbeitung der
						Anfrage und für den Fall von Anschlussfragen bei uns gespeichert. Diese Daten geben wir nicht
						ohne Ihre Einwilligung weiter.
						<br />
						Die Verarbeitung der in das Kontaktformular eingegebenen Daten erfolgt somit ausschließlich auf
						Grundlage Ihrer Einwilligung (Art. 6 Abs. 1 lit. a DSGVO). Sie können diese Einwilligung
						jederzeit widerrufen. Dazu reicht eine formlose Mitteilung per E-Mail an uns. Die Rechtmäßigkeit
						der bis zum Widerruf erfolgten Datenverarbeitungsvorgänge bleibt vom Widerruf unberührt.
						<br />
						Die von Ihnen im Kontaktformular eingegebenen Daten verbleiben bei uns, bis Sie uns zur Löschung
						auffordern, Ihre Einwilligung zur Speicherung widerrufen oder der Zweck für die Datenspeicherung
						entfällt (z. B. nach abgeschlossener Bearbeitung Ihrer Anfrage). Zwingende gesetzliche
						Bestimmungen – insbesondere Aufbewahrungsfristen – bleiben unberührt.
					</p>
				</div>
			</div>
		</div>
	);
}

export default Datenschutz;
