import React, { PropsWithChildren, createContext, useContext, useState, useEffect } from "react";
import axios from "axios";

export interface CustomImageFile {
	id: number;
	imageUrl: string;
	product: CustomProduct;
	articleId: number;
}

export interface CustomProduct {
	articleId: number;
	description: string;
	unit: string;
	searchTerm: string;
	additionalInfo: string;
	price: number;
	barcode: string;
	weight: number;
	weightUnit: string;
	basePriceUnit: string;
	available: number;
	productGroup: string;
	images: CustomImageFile[];
	active: boolean;
	showPrice: boolean;
}

interface CustomProductContextType {
	customProductList: CustomProduct[];
	customProduct?: CustomProduct;
	productGroups: string[];
	fetchProductById: (articleId: number) => void;
	fetchCustomProducts: (
		page?: number,
		sortBy?: string,
		sortDirection?: string,
		productGroup?: string,
		sortStatus?: string
	) => Promise<void>;
	searchProducts: (term: string, page?: number, sortBy?: string, sortDirection?: string) => void;
	fetchProductGroups: () => void;
	totalPages: number;
	totalProducts: number;
	currentPage: number;
	setCurrentPage: (page: number) => void;
}

export const CustomProductContext = createContext<CustomProductContextType>({} as CustomProductContextType);

export const CustomProductProvider: React.FC<PropsWithChildren<object>> = ({ children }) => {
	const [customProductList, setCustomProductList] = useState<CustomProduct[]>([]);
	const [customProduct, setCustomProduct] = useState<CustomProduct>();
	const [totalPages, setTotalPages] = useState<number>(0);
	const [totalProducts, setTotalProducts] = useState<number>(0);
	const [currentPage, setCurrentPage] = useState<number>(1);
	const [productGroups, setProductGroups] = useState<string[]>([]);
	const [categories, setCategories] = useState<string[]>([]);

	const fetchProductById = async (articleId: number) => {
		try {
			const response = await axios.get(`/api/custom/products/${articleId}`, {
				headers: {
					"Content-Type": "application/json",
				},
			});

			if (response.status === 200) {
				setCustomProduct(response.data);
			} else {
				console.error("Error fetching product:", response.status);
			}
		} catch (error) {
			console.error("Error fetching product:", error);
		}
	};

	async function fetchCustomProducts(
		page = 1,
		sortBy = "articleId",
		sortDirection = "ASC",
		productGroup: string | null = null,
		sortStatus: string | undefined = undefined
	) {
		try {
			const params: any = {
				page: page.toString(),
				sortBy,
				sortDirection,
				...(productGroup && { productGroup }),
			};

			if (sortStatus) {
				sortStatus.split("&").forEach((filter) => {
					const [field, value] = filter.split("=");
					if (field && value) {
						params[field] = value;
					}
				});
			}

			const response = await axios.get(`/api/custom/products`, {
				params,
				headers: {
					"Content-Type": "application/json",
				},
			});

			if (response.status === 200) {
				setCustomProductList(response.data.products);
				setTotalPages(response.data.totalPages);
				setTotalProducts(response.data.total);
			} else if (response.status === 401) {
			} else {
				console.error("Unexpected status code:", response.status);
			}
		} catch (error) {
			console.error("Beim Laden der Produkte ist ein Fehler aufgetreten. Bitte versuchen Sie es später erneut.");
		}
	}

	async function searchProducts(term = "", page = 1, sortBy = "price", sortDirection = "ASC") {
		try {
			const params = {
				term,
				page: page.toString(),
				sortBy,
				sortDirection,
			};
			const response = await axios.get(`/api/custom/search`, {
				params,
				headers: {
					"Content-Type": "application/json",
				},
			});

			if (response.status === 200) {
				const data = response.data.products;
				const totalTemp = response.data.total;
				const totalPagesTemp = response.data.totalPages;
				const pageTemp = response.data.page;
				setCustomProductList(data);
				setTotalPages(totalPagesTemp);
				setTotalProducts(totalTemp);
				setCurrentPage(pageTemp);
			}
		} catch (error) {
			console.error("Beim Suchen der Produkte ist ein Fehler aufgetreten. Bitte versuchen Sie es später erneut.");
		}
	}

	const fetchProductGroups = async () => {
		try {
			const response = await axios.get(`/api/custom/product-groups`, {
				headers: {
					"Content-Type": "application/json",
				},
			});
			if (response.status === 200) {
				setProductGroups(response.data);
			} else {
				console.error("Error fetching product groups:", response.status);
			}
		} catch (error) {
			console.error("Error fetching product groups:", error);
		}
	};

	useEffect(() => {
		fetchCustomProducts();
		fetchProductGroups();
	}, [categories]);

	return (
		<CustomProductContext.Provider
			value={{
				fetchCustomProducts,
				customProductList,
				productGroups,
				fetchProductGroups,
				searchProducts,
				totalPages,
				totalProducts,
				currentPage,
				setCurrentPage,
				fetchProductById,
				customProduct,
			}}
		>
			{children}
		</CustomProductContext.Provider>
	);
};

export const useCustomProducts = () => useContext(CustomProductContext);
