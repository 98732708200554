import React from 'react';
import Marquee from "react-fast-marquee";
import "./LandingHero.css"

const MarqueeComponent: React.FC = () => {
    return (
        <Marquee className='fly-in delay-fly-1 uppercase' autoFill gradient gradientWidth={100} speed={50} pauseOnHover>
            <span className='px-2 xl:px-4 text-sm xl:text-xl font-meidum'>Türen</span>
            <span className='px-2'>&#8226;</span>
            <span className='px-2 xl:px-4 text-sm xl:text-xl font-meidum'>Tore</span>
            <span className='px-2'>&#8226;</span>
            <span className='px-2 xl:px-4 text-sm xl:text-xl font-meidum'>Geländer</span>
            <span className='px-2'>&#8226;</span>
            <span className='px-2 xl:px-4 text-sm xl:text-xl font-meidum'>Hallenverkleidungen</span>
            <span className='px-2'>&#8226;</span>
            <span className='px-2 xl:px-4 text-sm xl:text-xl font-meidum'>Treppen</span>
            <span className='px-2'>&#8226;</span>
            <span className='px-2 xl:px-4 text-sm xl:text-xl font-meidum'>Carports</span>
            <span className='px-2'>&#8226;</span>
            <span className='px-2 xl:px-4 text-sm xl:text-xl font-meidum'>Balkone</span>
            <span className='px-2'>&#8226;</span>
        </Marquee>
    );
};

export default MarqueeComponent;
