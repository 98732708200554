import { Link, useNavigate } from "react-router-dom";
import logo from "../../../assets/images/fleischmannlogo.webp";
import "./Footer.css";
import { useEffect, useState } from "react";
import { Events, scroller } from "react-scroll";

function Footer() {
	const currentYear = new Date().getFullYear();
	const [isScrolled, setIsScrolled] = useState(false);
	const [activeButton, setActiveButton] = useState("");
	const navigate = useNavigate();
	useEffect(() => {
		const onScroll = () => {
			const scrollCheck = window.pageYOffset > 20;
			setIsScrolled(scrollCheck);

			// Positionen der Abschnitte prüfen
			updateActiveSectionOnScroll();
		};

		// Event Listener beim Mounten hinzufügen
		window.addEventListener("scroll", onScroll);

		// Abschnitt beim Laden der Seite erkennen
		updateActiveSectionOnScroll();

		// Clean-Up-Funktion beim Unmounten
		return () => {
			window.removeEventListener("scroll", onScroll);
			Events.scrollEvent.remove("begin");
			Events.scrollEvent.remove("end");
		};
	}, []);

	const updateActiveSectionOnScroll = () => {
		const offsetTop = window.pageYOffset;
		const windowHeight = window.innerHeight;
		const docHeight = document.documentElement.scrollHeight;
		const sections = {
			galOverview: document.getElementById("galOverview"),
			compAbout: document.getElementById("compAbout"),
			contactEmbed: document.getElementById("contactEmbed"),
		};

		let activeSection = "";

		// Keine Sektion aktivieren, wenn ganz oben
		if (offsetTop >= 150) {
			for (const [key, section] of Object.entries(sections)) {
				if (section) {
					const rect = section.getBoundingClientRect();
					const absoluteTop = rect.top + offsetTop;
					// Überprüfen, ob die Sektion in der Nähe der Bildschirmmitte ist
					if (absoluteTop - 350 <= offsetTop && absoluteTop + rect.height - 350 > offsetTop) {
						activeSection = key;
						break;
					}
				}
			}
		}

		// Wenn ganz unten, letzte Sektion aktivieren
		if (offsetTop + windowHeight >= docHeight) {
			activeSection = "";
		}

		setActiveButton(activeSection);
	};

	const jumpToSection = (sectionName: string) => {
		setActiveButton(sectionName);
		navigate("/");
		setTimeout(() => {
			scroller.scrollTo(sectionName, {
				duration: 800,
				delay: 0,
				smooth: "easeInOutQuart",
			});
		}, 0);
	};
	return (
		<div className="pt-auto bottom-0 left-0 w-full">
			<footer className="bg-gray-50 shadow ">
				<div className="w-full max-w-screen-xl mx-auto p-4 md:py-8">
					<div className="sm:flex sm:items-center sm:justify-between">
						<Link to="/" className="flex items-center mb-4 sm:mb-0 space-x-3 rtl:space-x-reverse">
							<img src={logo} className="h-8" alt="Flowbite Logo" />
							<span className="logo-span self-center text-2xl font-semibold whitespace-nowrap text-gray-900">
								Metallbau Fleischmann
							</span>
						</Link>
						<ul className="flex flex-wrap items-center mb-6 text-sm font-medium gap-6 text-gray-900 sm:mb-0 ">
							<li>
								<button
									onClick={() => jumpToSection("compAbout")}
									className="hover:bg-transparent bg-transparent"
								>
									Wir über Uns
								</button>
							</li>
							<li>
								<Link to={"/impressum"}>Impressum</Link>
							</li>
							<li>
								<Link to={"/datenschutz"}>Datenschutz</Link>
							</li>
						</ul>
					</div>
					<hr className="my-6 border-gray-900 sm:mx-auto lg:my-8" />
					<span className="block text-sm text-gray-700 sm:text-center ">
						&copy;{currentYear},&nbsp;Günter Fleischmann GmbH.
					</span>
				</div>
			</footer>
		</div>
	);
}

export default Footer;
