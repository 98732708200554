import { ChevronLeftIcon, ChevronRightIcon, ImageIcon, MixerHorizontalIcon } from "@radix-ui/react-icons";
import { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { Link } from "react-router-dom";
import { Button } from "src/components/ui/button";
import { Card } from "src/components/ui/card";
import { Input } from "src/components/ui/input";
import { Select, SelectContent, SelectGroup, SelectItem, SelectTrigger, SelectValue } from "src/components/ui/select";
import {
	Sheet,
	SheetClose,
	SheetContent,
	SheetFooter,
	SheetHeader,
	SheetTitle,
	SheetTrigger,
} from "src/components/ui/sheet";
import { useCustomProducts } from "src/context/ProductProvider";

export default function Shop() {
	const scrollToTop = useCallback(() => {
		window.scrollTo({ top: 0, behavior: "smooth" });
	}, []);

	const {
		customProductList = [],
		fetchCustomProducts,
		searchProducts,
		totalPages,
		currentPage,
		productGroups,
		setCurrentPage,
		fetchProductGroups,
	} = useCustomProducts();
	const [userSearchQuery, setUserSearchQuery] = useState<string>("");
	const [sortDirection, setSortDirection] = useState<string>("ASC");
	const [selectedSort, setSelectedSort] = useState<string | undefined>(undefined);
	const [sortBy, setSortBy] = useState<string>("articleId");
	const [selectedGroup, setSelectedGroup] = useState<string | undefined>(undefined);

	const memoizedFetchCustomProducts = useCallback(() => {
		fetchCustomProducts(currentPage, sortBy, sortDirection, selectedGroup);
	}, [fetchCustomProducts, currentPage, sortBy, sortDirection, selectedGroup]);
	const memoizedSearchProducts = useCallback(
		(userSearchQuery: string) => {
			searchProducts(userSearchQuery, 1);
		},
		[searchProducts]
	);

	function formatPrice(priceString: string): string {
		const price = parseFloat(priceString.replace(",", "."));
		const formattedPrice = price.toFixed(2);
		return formattedPrice.replace(".", ",").replace(/\B(?=(\d{3})+(?!\d))/g, ".");
	}

	useEffect(() => {
		// Lade die Produktgruppen nur einmalig, wenn die Komponente geladen wird
		fetchProductGroups();
	}, [productGroups]);

	useEffect(() => {
		// Handle Produktsuche, wenn userSearchQuery sich ändert
		if (userSearchQuery) {
			const delayDebounceFn = setTimeout(() => {
				memoizedSearchProducts(userSearchQuery);
			}, 500);

			return () => clearTimeout(delayDebounceFn);
		} else {
			memoizedFetchCustomProducts();
		}
	}, [userSearchQuery]);

	useEffect(() => {
		// Aktualisiere die Produktliste nur bei Änderungen in den Filtern und der Sortierung
		if (!userSearchQuery) {
			memoizedFetchCustomProducts();
		}
	}, [customProductList]);

	const handleGroupChange = useCallback(
		(value: string) => {
			if (value !== selectedGroup) {
				setCurrentPage(1);
				setSelectedGroup(value);
			}
		},
		[selectedGroup]
	);

	const handlePageSelectInc = useCallback(() => {
		const newPage = currentPage < totalPages ? currentPage + 1 : currentPage;
		if (newPage !== currentPage) {
			setCurrentPage(newPage);
			scrollToTop();
		}
	}, [currentPage, totalPages, setCurrentPage, scrollToTop]);

	const handlePageSelectDec = useCallback(() => {
		const newPage = currentPage > 1 ? currentPage - 1 : 1;
		if (newPage !== currentPage) {
			setCurrentPage(newPage);
			scrollToTop();
		}
	}, [currentPage, setCurrentPage, scrollToTop]);

	const handlePageSelectFirst = useCallback(() => {
		if (currentPage !== 1) {
			setCurrentPage(1);
			scrollToTop();
		}
	}, [currentPage, setCurrentPage, scrollToTop]);

	const handlePageSelectLast = useCallback(() => {
		if (currentPage !== totalPages) {
			setCurrentPage(totalPages);
			scrollToTop();
		}
	}, [totalPages, currentPage, setCurrentPage, scrollToTop]);

	const handleSearch = useCallback(() => {
		memoizedSearchProducts(userSearchQuery);
	}, [memoizedSearchProducts, userSearchQuery]);

	const handleResetFilters = useCallback(() => {
		setSelectedGroup(undefined);
		setSelectedSort(undefined);
		setSortDirection("ASC");
		setSortBy("articleId");
		setUserSearchQuery("");
		memoizedFetchCustomProducts();
	}, []);

	const renderPageNumbers = useMemo(() => {
		return <div className="flex items-center justify-center">{`Seite ${currentPage} von ${totalPages}`}</div>;
	}, [currentPage, totalPages]);

	return (
		<section className="flex flex-col w-full max-w-[1280px] mx-auto pt-36 items-start justify-center px-0 lg:px-4">
			<div className="flex h-full w-full flex-col items-center justify-end lg:pr-4">
				<div className="h-full w-full">
					<div className="w-full flex items-center justify-center gap-4 px-2 lg:px-0">
						<Sheet>
							<SheetTrigger asChild>
								<Button variant="default" className="flex items-center justify-center gap-2">
									Filter
									<MixerHorizontalIcon />
								</Button>
							</SheetTrigger>
							<SheetContent side={"left"} className="z-[99]">
								<SheetHeader>
									<SheetTitle className="text-2xl">Filter</SheetTitle>
								</SheetHeader>
								<div>
									<Select value={selectedGroup} onValueChange={handleGroupChange}>
										<SelectTrigger className="w-full mt-6 border-border border-[1px] rounded-lg p-2">
											<SelectValue placeholder="Warengruppe" />
										</SelectTrigger>
										<SelectContent className="w-full z-[99]">
											<SelectGroup>
												{productGroups && productGroups.length > 0 ? (
													productGroups.map((group) => (
														<SelectItem value={group} key={group}>
															{group}
														</SelectItem>
													))
												) : (
													<></>
												)}
											</SelectGroup>
										</SelectContent>
									</Select>
								</div>
								<SheetFooter className="flex-col items-center justify-center mt-8">
									<SheetClose asChild>
										<Button type="submit" className="w-full">
											Schließen
										</Button>
									</SheetClose>
								</SheetFooter>
								<Button variant="ghost" className="w-full mt-4" onClick={handleResetFilters}>
									Filter zurücksetzen
								</Button>
							</SheetContent>
						</Sheet>
						<Input
							type="text"
							placeholder="Suche..."
							value={userSearchQuery}
							onChange={(e) => setUserSearchQuery(e.target.value)}
							autoComplete="off"
						/>
						<Button className="w-max" variant={"default"} onClick={handleSearch}>
							Suche
						</Button>
					</div>
					<section className="mt-2 w-full">
						<div className="flex w-full flex-col items-center justify-center">
							<div className="w-full grid grid-cols-1 gap-x-2 gap-y-2 bg-white lg:grid-cols-2 xl:grid-cols-3">
								{Array.isArray(customProductList) && customProductList.length > 0 ? (
									customProductList.map((product) => (
										<div className="mx-auto w-full" key={product.articleId}>
											<Link
												className="flex flex-col items-center justify-center"
												to={`/shop/${product.articleId}`}
												key={product.articleId}
											>
												<Card className="relative flex h-full min-h-[300px] w-full flex-col items-center justify-between md:min-h-[350px]">
													<div className="flex h-full w-full items-center justify-center rounded bg-white">
														{Array.isArray(product.images) &&
														product.images?.length !== 0 ? (
															<div className="h-[500px] max-h-[500px] w-full rounded border object-cover shadow">
																<img
																	src={`https://arcview.nodiatech.de${product.images[0].imageUrl}`}
																	alt={product.images[0].imageUrl}
																	className="h-full max-h-[500px] w-full rounded object-cover"
																/>
															</div>
														) : (
															<div className="flex h-[500px] w-full items-center justify-center rounded border object-contain shadow">
																<ImageIcon className="h-16 w-16 text-sidebarGray-500" />
															</div>
														)}
													</div>
													<div className="flex h-full w-full flex-col items-start justify-between px-2 py-4 text-[#121212] lg:px-2">
														<div className="w-full flex items-center justify-between">
															<h1 className="text-md font-bold">{product.searchTerm}</h1>
															<span className="p-1 border-orange-300 text-orange-300 bg-orange-800 rounded-lg text-xs">
																Ladenverkauf
															</span>
														</div>
														<p className="max-w-[50ch] text-sm">
															{product.additionalInfo &&
															product.additionalInfo.length > 50
																? product.description.substring(0, 50) + "..."
																: product.description}
														</p>
														{product.showPrice ? (
															<span className="text-md mt-4 font-bold">
																{formatPrice(product.price.toString())}€
															</span>
														) : (
															<p className="text-sm mt-4 text-gray-500">
																Preis nur auf Anfrage
															</p>
														)}
													</div>
												</Card>
											</Link>
										</div>
									))
								) : (
									<div className="pt-16 text-2xl font-medium w-full flex-nowrap">
										Es konnte keine Artikel gefunden werden
									</div>
								)}
							</div>
						</div>
					</section>
				</div>
			</div>
			{Array.isArray(customProductList) && customProductList.length > 0 ? (
				<div className="flex h-12 w-full items-center justify-center gap-4 -space-x-px text-sm py-12">
					<button
						className="rounded-lg border-[1px] border-sidebarGray-400 px-2 py-1"
						onClick={handlePageSelectFirst}
					>
						Start
					</button>
					<button
						className="flex items-center justify-center rounded-xl px-4 py-2 hover:bg-secondary"
						onClick={handlePageSelectDec}
					>
						<ChevronLeftIcon />
						Zurück
					</button>
					{renderPageNumbers}
					<button
						className="flex items-center justify-center rounded-xl px-4 py-2 hover:bg-secondary"
						onClick={handlePageSelectInc}
					>
						Weiter
						<ChevronRightIcon />
					</button>
					<button
						className="rounded-lg border-[1px] border-sidebarGray-400 px-2 py-1"
						onClick={handlePageSelectLast}
					>
						Ende
					</button>
				</div>
			) : (
				<></>
			)}
		</section>
	);
}
