import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import logo from "../../../assets/images/fleischmannlogo.webp";
import "./Navbar.css";
import { Events, scroller } from "react-scroll";

const Navbar = () => {
	const [isActive, setIsActive] = useState(false);
	const [isScrolled, setIsScrolled] = useState(false);
	const [activeButton, setActiveButton] = useState("");
	const navigate = useNavigate();
	const handleMenuClick = () => {
		setIsActive(!isActive);
	};

	useEffect(() => {
		const onScroll = () => {
			const scrollCheck = window.pageYOffset > 20;
			setIsScrolled(scrollCheck);

			// Positionen der Abschnitte prüfen
			updateActiveSectionOnScroll();
		};

		// Event Listener beim Mounten hinzufügen
		window.addEventListener("scroll", onScroll);

		// Abschnitt beim Laden der Seite erkennen
		updateActiveSectionOnScroll();

		// Clean-Up-Funktion beim Unmounten
		return () => {
			window.removeEventListener("scroll", onScroll);
			Events.scrollEvent.remove("begin");
			Events.scrollEvent.remove("end");
		};
	}, []);

	const updateActiveSectionOnScroll = () => {
		const offsetTop = window.pageYOffset;
		const windowHeight = window.innerHeight;
		const docHeight = document.documentElement.scrollHeight;
		const sections = {
			galOverview: document.getElementById("galOverview"),
			compAbout: document.getElementById("compAbout"),
			contactEmbed: document.getElementById("contactEmbed"),
		};

		let activeSection = "";

		// Keine Sektion aktivieren, wenn ganz oben
		if (offsetTop >= 150) {
			for (const [key, section] of Object.entries(sections)) {
				if (section) {
					const rect = section.getBoundingClientRect();
					const absoluteTop = rect.top + offsetTop;
					// Überprüfen, ob die Sektion in der Nähe der Bildschirmmitte ist
					if (absoluteTop - 350 <= offsetTop && absoluteTop + rect.height - 350 > offsetTop) {
						activeSection = key;
						break;
					}
				}
			}
		}

		// Wenn ganz unten, letzte Sektion aktivieren
		if (offsetTop + windowHeight >= docHeight) {
			activeSection = "contactEmbed";
		}

		setActiveButton(activeSection);
	};

	const jumpToSection = (sectionName: string) => {
		setActiveButton(sectionName);
		navigate("/");
		setTimeout(() => {
			scroller.scrollTo(sectionName, {
				duration: 800,
				delay: 0,
				smooth: "easeInOutQuart",
			});
		}, 0);
		setIsActive(false);
	};

	return (
		<nav
			className={`g-nav z-[99] h-[80px] header bg-[#fff] ${isActive ? "active" : ""} ${
				isScrolled ? "scrolled drop-shadow-md" : ""
			}`}
		>
			<div className="g-nav__content-wrapper">
				<div className="logo-wrapper">
					<Link to="/" className="flex items-center gap-6">
						<img src={logo} className="lg:w-24 lg:h-16 w-16 h-12" alt="" />
						<span className="text-xl text-gray-50 font-bold logo-span">Metallbau Fleischmann</span>
					</Link>
				</div>
				<div className="horizMenu">
					<ul className="horizNav-list">
						<li>
							<Link
								to={"/shop"}
								onClick={() => {
									setIsActive(false);
									setActiveButton("");
								}}
								className="text-base font-medium px-4 py-2 border-[1px] hover:border-[#38387c]"
							>
								Shop
							</Link>
						</li>
						<li>
							<button
								onClick={() => jumpToSection("galOverview")}
								className={`text-base font-medium px-4 py-2 ${
									activeButton === "galOverview"
										? "active bg-[#38387c] hover:bg-[#38387c] text-white"
										: "hover:bg-[#38387c] hover:text-white"
								}`}
							>
								Galerie
							</button>
						</li>
						<li>
							<button
								onClick={() => jumpToSection("compAbout")}
								className={`text-base font-medium px-4 py-2 ${
									activeButton === "compAbout"
										? "active bg-[#38387c] hover:bg-[#38387c] text-white"
										: "hover:bg-[#38387c] hover:text-white"
								}`}
							>
								Wer wir sind
							</button>
						</li>
						<li>
							<button
								onClick={() => jumpToSection("contactEmbed")}
								className={`text-base font-medium px-4 py-2 ${
									activeButton === "contactEmbed"
										? "active bg-[#38387c] hover:bg-[#38387c] text-white"
										: "hover:bg-[#38387c] hover:text-white"
								}`}
							>
								Kontakt
							</button>
						</li>
					</ul>
				</div>
				<div className={`menu-wrapper ${isActive ? "active" : ""}`} onClick={handleMenuClick}>
					<div className={`menu-container ${isActive ? "active" : ""}`}>
						<div className="line line-1">
							<div className="line-inner bg-gray-800 line-inner-1"></div>
						</div>
						<div className="line line-2">
							<div className="line-inner bg-gray-800 line-inner-2"></div>
						</div>
					</div>
					<p className="text-gray-800">Menü</p>
				</div>
				<div className={`menu-items-container ${isActive ? "active" : ""}`}>
					<ul>
						<li>
							<Link
								to={"/shop"}
								onClick={() => {
									setIsActive(false);
									setActiveButton("");
								}}
								className="text-base font-medium px-4 py-2 border-[1px] hover:border-[#38387c]"
							>
								Shop
							</Link>
						</li>
						<li className={`mc-li-1 ${isActive ? "active" : ""} `}>
							<button
								onClick={() => jumpToSection("galOverview")}
								className={`text-base font-medium px-4 py-2 ${
									activeButton === "galOverview"
										? "active bg-[#38387c] hover:bg-[#38387c] text-white"
										: "hover:bg-transparent"
								}`}
							>
								Galerie
							</button>
						</li>
						<li className={`mc-li-2 ${isActive ? "active" : ""} `}>
							<button
								onClick={() => jumpToSection("compAbout")}
								className={`text-base font-medium px-4 py-2 ${
									activeButton === "compAbout"
										? "active bg-[#38387c] hover:bg-[#38387c] text-white"
										: "hover:bg-transparent"
								}`}
							>
								Wer wir sind
							</button>
						</li>
						<li className={`mc-li-3 ${isActive ? "active" : ""}`}>
							<button
								onClick={() => jumpToSection("contactEmbed")}
								className={`text-base font-medium px-4 py-2 ${
									activeButton === "contactEmbed"
										? "active bg-[#38387c] hover:bg-[#38387c] text-white"
										: "hover:bg-transparent"
								}`}
							>
								Kontakt
							</button>
						</li>
					</ul>
				</div>
			</div>
		</nav>
	);
};

export default Navbar;
