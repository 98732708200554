// src/pages/CategoryPage.tsx

import React, { useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { useContext } from "react";
import { ContentContext } from "src/context/ContentProvider";
import { Separator } from "../../ui/separator";
import { Button } from "src/components/ui/button";

const CategoryPage: React.FC = () => {
	const { id } = useParams<{ id: string }>();
	const navigate = useNavigate();
	const { categories, fetchCategories } = useContext(ContentContext);
	const category = categories.find((cat) => cat.id === parseInt(id || ""));

	useEffect(() => {
		fetchCategories();
	});

	if (!category) {
		return <div>Diese Kategorie wurde nicht gefunden.</div>;
	}

	return (
		<div className="h-[98vh] overflow-scroll z-[92] p-8">
			<div className="flex flex-col items-start justify-start gap-8">
				<h1 className="text-4xl text-center w-full text-primary">{category.name}</h1>
				<Separator />
			</div>
			<div className="grid gap-4 py-4 grid-cols-6">
				{category.images.map((image) => (
					<div key={image.id} className="cursor-pointer" onClick={() => navigate(`/image/${image.id}`)}>
						<img
							className="h-full aspect-square w-full object-cover"
							src={`https://arcview.nodiatech.de${image.imageUrl}`}
							alt={image.title}
						/>
					</div>
				))}
			</div>
			<Button variant="default" className="mt-4" onClick={() => navigate(-1)}>
				Zurück
			</Button>
		</div>
	);
};

export default CategoryPage;
