import React, { useContext, useEffect, useState } from "react";
import LandingHero from "../../components/ui__integration/hero/LandingHero";
import LandingCategoryGallery from "../../components/ui__integration/landingCategoryGallery/LandingCategoryGallery";
import "./Landingpage.css";
import MarqueeSlider from "../../components/ui__integration/hero/MarqueeSlider";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css/bundle";
import "../../assets/styles/styles.css";
import { Navigation, Pagination, Mousewheel, Keyboard, Zoom, Autoplay } from "swiper/modules";
import Contact from "../../components/ui__integration/contact/Contact";
import Unternehmen from "../comInfo/Unternehmen";
import { ContentContext } from "src/context/ContentProvider";
import { useNavigate } from "react-router-dom";
import { Events, scroller } from "react-scroll";
interface LandingpageProps {}

const Landingpage: React.FC<LandingpageProps> = (props) => {
	const { category, fetchCategoryById } = useContext(ContentContext);
	const [isActive, setIsActive] = useState(false);
	const [isScrolled, setIsScrolled] = useState(false);
	const [activeButton, setActiveButton] = useState("");
	const navigate = useNavigate();

	useEffect(() => {
		const onScroll = () => {
			const scrollCheck = window.pageYOffset > 20;
			setIsScrolled(scrollCheck);

			updateActiveSectionOnScroll();
		};

		window.addEventListener("scroll", onScroll);

		updateActiveSectionOnScroll();

		// Clean-Up-Funktion beim Unmounten
		return () => {
			window.removeEventListener("scroll", onScroll);
			Events.scrollEvent.remove("begin");
			Events.scrollEvent.remove("end");
		};
	}, []);

	useEffect(() => {
		fetchCategoryById(1);
		document.title = "Metallbau-Fleischmann | Startseite";
	});

	const updateActiveSectionOnScroll = () => {
		const offsetTop = window.pageYOffset;
		const sections = {
			galOverview: document.getElementById("galOverview"),
		};

		let activeSection = "";

		// Keine Sektion aktivieren, wenn ganz oben
		if (offsetTop >= 150) {
			for (const [key, section] of Object.entries(sections)) {
				if (section) {
					const rect = section.getBoundingClientRect();
					const absoluteTop = rect.top + offsetTop;
					// Überprüfen, ob die Sektion in der Nähe der Bildschirmmitte ist
					if (absoluteTop - 350 <= offsetTop && absoluteTop + rect.height - 350 > offsetTop) {
						activeSection = key;
						break;
					}
				}
			}
		}
	};

	const pagination = {
		clickable: true,
		dynamicBullets: false,
		renderBullet: function (index: number, className: string) {
			return '<span class="' + className + '"></span>';
		},
	};

	const jumpToSection = (sectionName: string) => {
		setActiveButton(sectionName);
		navigate("/");
		setTimeout(() => {
			scroller.scrollTo(sectionName, {
				duration: 800,
				delay: 0,
				smooth: "easeInOutQuart",
			});
		}, 0);
		setIsActive(false);
	};

	return (
		<div className="lp-container relative">
			<div className="w-full h-[calc(100vh-80px)] mt-[80px] mx-auto">
				<Swiper
					cssMode={false}
					navigation={true}
					pagination={pagination}
					mousewheel={false}
					keyboard={true}
					zoom={true}
					autoplay={{
						delay: 5000,
						disableOnInteraction: false,
						pauseOnMouseEnter: true,
					}}
					modules={[Navigation, Pagination, Mousewheel, Keyboard, Zoom, Autoplay]}
					className="relative h-full w-full flex items-center justify-center"
				>
					{category &&
						category.images.map((image) => (
							<div className="relative" key={image.id}>
								<SwiperSlide className="flex items-center justify-center">
									<img
										src={`https://arcview.nodiatech.de${image.imageUrl}`}
										className="w-full h-full object-cover"
										alt=""
									/>
								</SwiperSlide>
								{/* <LandingHero /> */}
							</div>
						))}
				</Swiper>
				<div className="w-full absolute h-[calc(100vh-80px)] top-0 flex-col flex items-center justify-center z-[80]">
					<div className="">
						<h1
							className="lg:text-6xl text-2xl text-white drop-shadow-2xl p-0 m-0"
							style={{ textShadow: "0px 2px 4px rgba(0, 0, 0, 0.8)" }}
						>
							Metallbau Fleischmann
						</h1>
						<p
							className="lg:text-xl text-base text-white drop-shadow-2xl mt-3 pl-1"
							style={{ textShadow: "0px 2px 4px rgba(0, 0, 0, 0.8)" }}
						>
							Ihr Metallbaubetrieb aus Mylau.
						</p>
						<button
							onClick={() => jumpToSection("galOverview")}
							className={`text-base font-medium mt-8 ml-1 px-4 py-2 z-[80] border-[1px] border-gray-700 ${
								activeButton === "galOverview"
									? "active bg-[#38387c] hover:bg-[#38387c] text-white"
									: "bg-black/75 hover:bg-[#38387c] text-white"
							}`}
						>
							Mehr erfahren
						</button>
					</div>
				</div>
			</div>
			<div className="relative h-auto isolate w-full mx-[48px] max-sm:mx-[14px]">
				<div className="pb-4 w-full pt-4 mx-auto">
					<MarqueeSlider />
				</div>
			</div>
			<div className="relative h-auto isolate w-full mx-[48px] max-sm:mx-[14px] bg-gray-200" id="galOverview">
				<LandingCategoryGallery />
			</div>
			<div className="relative h-min isolate w-full mx-[48px] max-sm:mx-[14px] bg-white" id="compAbout">
				<Unternehmen />
			</div>
			<div className="relative h-min isolate w-full mx-[48px] max-sm:mx-[14px] bg-white" id="contactEmbed">
				<Contact />
			</div>
		</div>
	);
};

export default Landingpage;
