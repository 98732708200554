// src/components/LandingCategoryGallery.tsx

import React, { useContext, useEffect } from "react";
import { ContentContext } from "src/context/ContentProvider";
import { useNavigate } from "react-router-dom";

const LandingCategoryGallery: React.FC = () => {
	const { categories, fetchCategories } = useContext(ContentContext);
	const navigate = useNavigate();

	useEffect(() => {
		fetchCategories();
	}, [fetchCategories]);

	return (
		<div className="relative h-full isolate pt-[100px] max-w-[1280px] max-[1328px]:mx-[48px] max-sm:mx-[14px] mb-24 mx-auto">
			<h1 className="text-4xl font-extrabold text-left pb-12">
				Galerie&nbsp;
				<small className="ms-2 font-semibold text-gray-700 max-sm:hidden">
					Entdecken Sie unsere Arbeiten der letzten Jahre.
				</small>
			</h1>
			{categories && categories.length !== 0 ? (
				<div className="relative grid sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6 gap-y-12 z-[99]">
					{categories.map((category) => (
						<div
							key={category.id}
							className="relative lg-contentHolder w-full h-full z-[92] cursor-pointer"
							onClick={() => navigate(`/category/${category.id}`)}
						>
							<img
								className="h-full aspect-square w-full object-cover"
								src={`https://arcview.nodiatech.de${category.images[0].imageUrl}`}
								alt={category.images[0].title}
							/>
							<p className="text-center rtl:text-right text-gray-700 dark:text-gray-700">
								{category.name}
							</p>
						</div>
					))}
				</div>
			) : (
				<div>Wir arbeiten aktuell daran, Ihnen unsere hochwertigen Bilder zur Verfügung zu stellen.</div>
			)}
		</div>
	);
};

export default LandingCategoryGallery;
