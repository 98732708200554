// src/pages/ImagePage.tsx

import React from "react";
import { useParams, useNavigate } from "react-router-dom";
import { useContext } from "react";
import { ContentContext } from "src/context/ContentProvider";
import { Button } from "src/components/ui/button";

const ImagePage: React.FC = () => {
	const { id } = useParams<{ id: string }>();
	const navigate = useNavigate();
	const { categories } = useContext(ContentContext);
	const image = categories.flatMap((category) => category.images).find((img) => img.id === parseInt(id || ""));

	if (!image) {
		return <div>Dieses Bild wurde nicht gefunden.</div>;
	}

	return (
		<div className="absolute top-0 left-0 w-full h-full flex flex-col items-center justify-center bg-black/25 backdrop-blur-sm z-[99]">
			<p className="text-gray-900 text-xl h-[7vh] flex items-center justify-center">{image.title}</p>
			<img
				className="object-contain w-[90vw] h-[90vh]"
				src={`https://arcview.nodiatech.de${image.imageUrl}`}
				alt={image.title}
			/>
			<Button variant="default" className="mt-4 h-[3vh]" onClick={() => navigate(-1)}>
				Schließen
			</Button>
		</div>
	);
};

export default ImagePage;
