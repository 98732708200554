import React, { useContext, useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import { useCustomProducts } from "src/context/ProductProvider";
import { Carousel, CarouselContent, CarouselItem, CarouselApi } from "../../components/ui/carousel";
import { Card } from "../../components/ui/card";
import { Accordion, AccordionItem, AccordionTrigger, AccordionContent } from "../../components/ui/accordion";
import { CheckCircledIcon, ExclamationTriangleIcon } from "@radix-ui/react-icons";

const ProductDetail: React.FC = () => {
	const { articleId } = useParams<{ articleId: string }>();
	const {
		customProductList = [],
		fetchCustomProducts,
		searchProducts,
		fetchProductGroups,
		productGroups,
		totalPages,
		currentPage,
		setCurrentPage,
		totalProducts,
		fetchProductById,
		customProduct,
	} = useCustomProducts();

	const [mainApi, setMainApi] = useState<CarouselApi>();
	const [thumbnailApi, setThumbnailApi] = useState<CarouselApi>();
	const [current, setCurrent] = useState(0);

	function formatPrice(priceString: string): string {
		const price = parseFloat(priceString.replace(",", "."));
		const formattedPrice = price.toFixed(2);
		return formattedPrice.replace(".", ",").replace(/\B(?=(\d{3})+(?!\d))/g, ".");
	}

	const mainImage = useMemo(
		() =>
			Array.isArray(customProduct?.images) &&
			customProduct?.images.map((image, index) => (
				<CarouselItem key={index} className="relative w-full">
					<img
						src={`https://arcview.nodiatech.de${image.imageUrl}`}
						alt={`id`}
						style={{
							objectFit: "cover",
							width: "100%",
							height: "100%",
						}}
					/>
				</CarouselItem>
			)),
		[customProduct?.images]
	);

	const handleClick = (index: number) => {
		if (!mainApi || !thumbnailApi) {
			return;
		}
		thumbnailApi.scrollTo(index);
		mainApi.scrollTo(index);
		setCurrent(index);
	};

	const thumbnailImages = useMemo(
		() =>
			Array.isArray(customProduct?.images) &&
			customProduct?.images.map((image, index) => (
				<CarouselItem
					key={index}
					className="relative m-0 aspect-square w-full basis-1/4 p-0"
					onClick={() => handleClick(index)}
				>
					<img
						className={`${index === current ? "border-[2px]" : ""}`}
						src={`https://arcview.nodiatech.de${image.imageUrl}`}
						alt={`id`}
						style={{
							objectFit: "cover",
							width: "100%",
							height: "100%",
						}}
					/>
				</CarouselItem>
			)),
		[current, customProduct?.images, handleClick]
	);

	useEffect(() => {
		if (!mainApi || !thumbnailApi) {
			return;
		}

		const handleTopSelect = () => {
			const selected = mainApi.selectedScrollSnap();
			setCurrent(selected);
			thumbnailApi.scrollTo(selected);
		};

		const handleBottomSelect = () => {
			const selected = thumbnailApi.selectedScrollSnap();
			setCurrent(selected);
			mainApi.scrollTo(selected);
		};

		mainApi.on("select", handleTopSelect);
		thumbnailApi.on("select", handleBottomSelect);

		return () => {
			mainApi.off("select", handleTopSelect);
			thumbnailApi.off("select", handleBottomSelect);
		};
	}, [mainApi, thumbnailApi]);

	useEffect(() => {
		if (articleId) {
			fetchProductById(Number(articleId));
			console.log(customProduct);
		}
	}, [articleId, customProduct, fetchProductById]);

	if (!customProduct) {
		return <div>Laden...</div>;
	}

	return (
		<div className="lg:mt-56 mt-24 grid grid-cols-1 lg:grid-cols-2 px-2 lg:px-0 gap-4 w-full max-w-[1280px] lg:flex lg:flex-row-reverse">
			<div className="h-full w-full">
				<Card className="p-4">
					<div className="flex items-center justify-between">
						<div className="flex flex-col items-start justify-start gap-2">
							<small className="border-border border-[1px] text-base w-max rounded-md px-1 bg-blue-300 text-blue-900">
								{customProduct.productGroup}
							</small>
							<h1 className="text-3xl font-semibold">{customProduct.searchTerm}</h1>
						</div>
						<div className="flex flex-col items-start justify-start gap-2">
							{customProduct.showPrice ? (
								<div className="flex flex-col items-start justify-start">
									<span className="text-2xl mt-4 font-bold">
										{formatPrice(customProduct.price.toString())}€
									</span>
									<span className="text-xs">inklusive MwSt.</span>
								</div>
							) : (
								<p className="text-sm mt-4 text-gray-500">Preis nur auf Anfrage</p>
							)}
						</div>
					</div>
					<div className="flex flex-col mt-8">
						<div className="flex flex-col gap-3 items-start justify-start">
							<h1 className="font-medium text-xl">Produktbeschreibung</h1>
							<p>{customProduct.description}</p>
						</div>
						<div className="mt-8 flex items-center justify-start border-gray-800 text--gray-800 bg-blue-300 rounded-lg py-1 px-4">
							<ExclamationTriangleIcon />
							<span className="p-1 text-base">
								Dieses Produkt kann aktuell leider nur vor Ort erworben werden.
							</span>
						</div>
					</div>
				</Card>
			</div>
			<div className="flex w-full flex-col items-center justify-center">
				<Carousel setApi={setMainApi} className="w-full">
					<CarouselContent className="w-full h-full">{mainImage}</CarouselContent>
				</Carousel>
				<Carousel setApi={setThumbnailApi}>
					<CarouselContent className="">{thumbnailImages}</CarouselContent>
				</Carousel>
			</div>
		</div>
	);
};

export default ProductDetail;
